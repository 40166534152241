const initialState = {
    lineAccessToken: '',
    userData: {},
    userLineData: {}
}

function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'LINE_USER_LOGIN':
            return {
                ...state,
                lineAccessToken: action.lineAccessToken
            };
        case 'SAVE_USER_DATA':
            return {
                ...state,
                userData: action.userData
            };
        case 'SAVE_USER_LINE_DATA':
            return {
                ...state,
                userLineData: action.userLineData
            };
        default:
            return state;
    }
}

export default userReducer